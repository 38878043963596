import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["eyeopen", "eyeclose", "input"];

  showPassword() {
    this.toggleEyeIcon();
    this.inputTarget.type = "text";
  }

  hidePassword() {
    this.toggleEyeIcon();
    this.inputTarget.type = "password";
  }

  toggleEyeIcon() {
    this.eyeopenTarget.classList.toggle("hide");
    this.eyecloseTarget.classList.toggle("hide");
  }
}
